"use client";
import React from "react";
import { useNavigate } from "react-router-dom";

// Student Dashboard
function StudentDashboard() {
    const navigate = useNavigate();
    const coursesData = ['DCA', 'ADCA', 'TYPING'];

    return (
        <div className="p-8">
            {
                // userData?.role === "director" &&
                <div className="">
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-4">
                        {
                            coursesData?.map((course, index) => (
                                <div className="bg-blue-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                                    <h2 className="text-xl">{course}</h2>
                                    <p className="flex justify-center">
                                        <button key={index} className="block text-sm bg-green-500 text-white p-2 rounded"
                                            onClick={() => navigate(`/home/studentexam?course=${course}`)}>Start {course} Exam</button>
                                    </p>
                                </div>
                            ))
                        }


                    </div>
                </div>
            }
        </div>
    );
}

export default StudentDashboard;