import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function StudentExam() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [searchParams] = useSearchParams();
  const course = searchParams.get('course');

  const dummyQuestions = [
    { course: 'DCA', text: 'What is 2 + 2?', options: ['3', '4', '5', '6'], correctAnswer: '2' },
    { course: 'ADCA', text: 'What is the boiling point of water?', options: ['90°C', '100°C', '110°C', '120°C'], correctAnswer: '2' }
  ];

  const filteredQuestions = dummyQuestions.filter(q => q.course === course);

  const handleAnswerSelect = (optionIndex) => {
    setAnswers({ ...answers, [currentQuestionIndex]: optionIndex });
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < filteredQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowResult(true);
    }
  };

  const calculateScore = () => {
    let score = 0;
    filteredQuestions.forEach((question, index) => {
      if (`${answers[index]}` === question.correctAnswer) {
        score += 1;
      }
    });
    return score;
  };

  if (!filteredQuestions.length) {
    return <p className="p-8">No questions available for the selected course: {course}</p>;
  }

  if (showResult) {
    return (
      <div className="p-8">
        <h1 className="text-2xl font-bold">Exam Completed</h1>
        <p>Your Score: {calculateScore()} / {filteredQuestions.length}</p>
      </div>
    );
  }

  const currentQuestion = filteredQuestions[currentQuestionIndex];

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Exam - {course}</h1>
      <p className="mb-2 bg-[#f2f2f2] p-4 rounded-md">{currentQuestion.text}</p>
      <ul>
        {currentQuestion.options.map((option, index) => (
         <li key={index} onClick={() => handleAnswerSelect(index)} className="cursor-pointer p-2 border rounded mb-2 hover:bg-gray-100">
         <label>
           <input
             type="radio"
             name="answer"
             checked={answers[currentQuestionIndex] === index}
             onChange={() => handleAnswerSelect(index)}
           />
           {option}
         </label>
       </li>
        ))}
      </ul>
      <button onClick={handleNextQuestion} className="mt-4 bg-[#ff0000] text-white p-2 rounded">
        {currentQuestionIndex === filteredQuestions.length - 1 ? 'Submit' : 'Next'}
      </button>
    </div>
  );
}

export default StudentExam;
