import React, { useContext, useEffect, useRef, useState } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { MyContext } from '../../../Context/MyContextProvider';
import TCertificat from '../../../Assets/t_c.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Http from '../../../Services/Http';
import { DateTime } from 'date-time-helper-x';


function T_Createcertificate() {
    const token = localStorage.getItem('token');
    const [inputClose, setinputClose] = useState(false);
    const { setT_CertificateCreate } = useContext(MyContext);
    const cartificateData = localStorage.getItem('cartificateData');
    const newCartificate = JSON.parse(cartificateData);
    const [serialno, setserialno] = useState('');
    const [startdate, setstartdate] = useState('');
    const [enddate, setenddate] = useState('');
    const [issuedate, setissuedate] = useState('');
    const [pastCartificate, setpastCartificate] = useState({});
    const [PDFbutton, setPDFbutton] = useState(false);
    const [SubmitButton, setSubmitButton] = useState(true);
    const [grade, setgrade] = useState('');
    const [completecourse, setcompletecourse] = useState('');
    const [course, setcourse] = useState('');

    const [stg_english, Setstg_english] = useState('');
    const [stg_hindi, Setstg_hindi] = useState('');
    const [stg_urdu, setstg_urdu] = useState('');
    const [typing_english, Settyping_english] = useState('');
    const [typing_hindi, Settyping_hindi] = useState('');
    const [typing_urdu, Settyping_urdu] = useState('');
    const [DiplomaSerialId, setDiplomaSerialId] = useState('');
    useEffect(() => {
        if (pastCartificate) {
            const { enddate, issuedate, serialno, startdate, completecourse, grade,
                MarksSheet_Id, DiplomaSerialId, course
            } = pastCartificate;
            setserialno(serialno);
            setstartdate(startdate);
            setcourse(course);
            setenddate(enddate);
            setissuedate(issuedate);
            setcompletecourse(completecourse);
            setgrade(grade);
            setDiplomaSerialId(DiplomaSerialId);
            Settyping_english(MarksSheet_Id?.typing_english);
            Settyping_hindi(MarksSheet_Id?.typing_hindi);
            Settyping_urdu(MarksSheet_Id?.typing_urdu);

            Setstg_english(MarksSheet_Id?.stg_english);
            Setstg_hindi(MarksSheet_Id?.stg_hindi);
            setstg_urdu(MarksSheet_Id?.stg_urdu);
        }
    }, [pastCartificate]);

    useEffect(() => {
        if (newCartificate) {
            setstartdate((prev) => prev || DateTime(newCartificate?.startDate, "DDMMYYYY"));
            setenddate((prev) => prev || DateTime(newCartificate?.toenddate, "DDMMYYYY"));
        }
    }, [newCartificate]);

    const certificateRef = useRef(null);

    const handleClose = () => {
        setT_CertificateCreate(false);
    }

    const handleDownloadPDF = () => {
        const input = certificateRef.current;

        setinputClose(true)

        setTimeout(() => {
            html2canvas(input, { scale: 2 }) // Increase scale for higher resolution
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF('p', 'mm', 'a4'); // Set orientation to landscape
                    pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 size: 297mm × 210mm (switch width and height for landscape)
                    pdf.save('Typing-certificate.pdf');
                    setinputClose(false)

                });
        }, 500);
    }
    ////////   create certificate  /////////

    const CertificateHandler = async () => {
        let serialno1 = `${serialnoHandler(newCartificate?.toenddate)}/T/${String(newCartificate?.aadharno).slice(-4)}`
        let startdate1 = startdate;
        let enddate1 = enddate;

        try {
            let res = await Http({
                url: `/payment/createcertificate`,
                method: "post",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    studentid: newCartificate?._id, category: "TYPING", serialno: serialno1.toUpperCase(),
                    startdate: startdate1, enddate: enddate1, issuedate, grade, course: course,
                    typing_english,
                    typing_hindi,
                    typing_urdu,
                    stg_english,
                    stg_hindi,
                    stg_urdu
                }
            });
            if (res?.data?.message === "newCertificate_Created_201") {
                setPDFbutton(true)
                setT_CertificateCreate(false)
            }
            if (res?.data?.message === "serialno_already_exists") {
                alert("Serial No is unique")
            }
        } catch (error) {
            console.log(`cartificate error ${error}`);
        }

    }
    ////////   get certificate  /////////

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getcertificate`,
                    method: "post",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data: { studentid: newCartificate?._id, category: "TYPING" }
                });
                setpastCartificate(res?.data?.certificate);
                if (res.data.message === "get_sussed_200") {
                    setPDFbutton(true)

                } if (res?.data?.certificate === null) {
                    setSubmitButton(false)
                }

            } catch (error) {
                console.log(`cartificate get error ${error}`);
            }
        }
        fetchData()
    }, [newCartificate?._id, token])

    ////////   update certificate   /////////

    const CertificateUpdateHandler = async () => {
        try {
            let res = await Http({
                url: `/payment/updatecertificate`,
                method: "patch",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    _id: pastCartificate?._id, serialno, course, startdate, enddate, issuedate, grade, DiplomaSerialId,
                    typing_english,
                    typing_hindi,
                    typing_urdu,
                    stg_english,
                    stg_hindi,
                    stg_urdu
                }
            });
            if (res.data.message === "updatedcertificate_Created_201") {
                setT_CertificateCreate(false)
            }


        } catch (error) {
            console.log(`cartificate error ${error}`);
        }
    }
    const serialnoHandler = (item) => {
        if (item) {
            const dateObject = new Date(item);
            return dateObject.getFullYear();
        } else {
            // Handle cases where item is null or undefined
            return null; // or throw an error, depending on your requirements
        }
    }
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="sidebar-same relative w-[90%] h-[95%] my-10 mx-auto  rounded-lg shadow-lg">
                <div onClick={handleClose} className='absolute top-2 right-2 cursor-pointer'>
                    <IoCloseSharp size={30} />
                </div>
                <div className='w-full h-full overflow-scroll'>
                    <div ref={certificateRef} className='h-[297mm] w-[210mm]' style={{ position: 'relative' }}>
                        <img src={TCertificat} alt='A_Certificat' className='w-full h-full' style={{ position: 'absolute', top: 0, left: 0 }} />
                        {/* Serial No */}
                        <h1 >
                            {inputClose ? (
                                <span
                                    className='absolute z-50 top-[40px] font-bold text-lg text-white  text-ellipsis left-48 '
                                >
                                    {`${serialnoHandler(newCartificate?.toenddate)}/T/${String(newCartificate?.aadharno).slice(-4)}`}
                                </span>
                            ) : (
                                <input
                                    type='text'
                                    value={`${serialnoHandler(newCartificate?.toenddate)}/T/${String(newCartificate?.aadharno).slice(-4)}`}
                                    onChange={(e) => setserialno(e?.target?.value)}
                                    className='bg-transparent absolute z-50 top-[50px] font-bold text-lg text-white  text-ellipsis left-48'
                                />
                            )} 
                        </h1>
                        {/* Student's Name */}
                        {
                            inputClose ?
                                <>
                                    <h1 className='absolute z-50 top-[490px] font-bold text-lg text-ellipsis left-[330px] '>
                                        {newCartificate?.studentname.toUpperCase()}
                                    </h1>
                                    <h1 className='absolute z-50 top-[527px] font-bold text-lg text-ellipsis left-[210px]  '>
                                        {newCartificate?.fathername.toUpperCase()}
                                    </h1>
                                    {/* Advanced Diploma in Computer Applications */}
                                    <h1 className='absolute z-50 top-[660px] font-bold text-lg  text-ellipsis left-[70px]  '>
                                        {course}
                                    </h1>
                                    <h1 className='absolute z-50 top-[45px] font-bold text-lg text-ellipsis right-10  '>
                                        Registration No - 61/2018
                                    </h1>
                                    {/* Franchise Name */}
                                    <h1 className='absolute z-50 top-[575px] font-bold text-base  text-ellipsis left-[90px]  '>
                                        {newCartificate?.franchise_or_director_id?.franchisename.toUpperCase()} {" "}
                                        {newCartificate?.franchise_or_director_id?.village.toUpperCase()} {","}
                                        {newCartificate?.franchise_or_director_id?.district.toUpperCase()} {","}
                                        {newCartificate?.franchise_or_director_id?.pincode} {","}
                                        {newCartificate?.franchise_or_director_id?.state.toUpperCase()}
                                    </h1>
                                </>
                                :
                                <>
                                    <h1 className='absolute z-50 top-[500px] font-bold text-lg text-ellipsis left-[330px] '>
                                        {newCartificate?.studentname.toUpperCase()}
                                    </h1>
                                    <h1 className='absolute z-50 top-[537px] font-bold text-lg text-ellipsis left-[210px]  '>
                                        {newCartificate?.fathername.toUpperCase()}
                                    </h1>
                                    {/* Advanced Diploma in Computer Applications */}
                                    <input
                                        type='text' value={course}
                                        onChange={(e) => setcourse(e.target.value)}
                                        className=' w-[90%] 
                                        bg-transparent border-[#f7811f] border-4 border-dashed outline-none
                                        absolute z-50 top-[660px] font-bold text-lg  text-ellipsis left-[70px]
                                        '/>

                                    <h1 className='absolute z-50 top-[54px] font-bold text-lg text-ellipsis right-10  '>
                                        Registration No - 61/2018
                                    </h1>
                                    {/* Franchise Name */}
                                    <h1 className='absolute z-50 top-[580px] font-bold text-base  text-ellipsis left-[90px]  '>
                                        {newCartificate?.franchise_or_director_id?.franchisename.toUpperCase()} {" "}
                                        {newCartificate?.franchise_or_director_id?.village.toUpperCase()} {","}
                                        {newCartificate?.franchise_or_director_id?.district.toUpperCase()} {","}
                                        {newCartificate?.franchise_or_director_id?.pincode} {","}
                                        {newCartificate?.franchise_or_director_id?.state.toUpperCase()}
                                    </h1>
                                </>
                        }

                        {

                            inputClose ?
                                <>
                                    {/* issue Date */}
                                    <span
                                        className='w-64  absolute z-50 bg-transparent pl-2 top-[775px] font-bold text-lg  text-ellipsis left-[100px] '
                                    >
                                        {issuedate}
                                    </span>

                                    {/* Start Date */}
                                    <span
                                        className='w-32  absolute z-50 bg-transparent pl-2 top-[620px] font-bold  text-ellipsis left-40 '
                                    >
                                        {startdate}
                                    </span>
                                    {/* End Date */}
                                    <span
                                        className='w-32  absolute z-50 bg-transparent pl-2 top-[620px] font-bold  text-ellipsis left-[320px] '
                                    >
                                        {enddate}
                                    </span>
                                    {/* marksheet data */}
                                    <span
                                        className='w-11  uppercase absolute z-50 bg-transparent top-[835px] font-semibold text-ellipsis left-[500px] '
                                    >
                                        {typing_english}
                                    </span>
                                    <span
                                        className='w-11  uppercase absolute z-50 bg-transparent top-[860px] font-semibold text-ellipsis left-[500px]'
                                    >
                                        {typing_hindi}
                                    </span>
                                    <span
                                        className='w-11  uppercase absolute z-50 bg-transparent top-[890px] font-semibold text-ellipsis left-[500px] '
                                    >
                                        {typing_urdu}
                                    </span>
                                    <span
                                        className='w-11  uppercase absolute z-50 bg-transparent top-[835px] font-semibold text-ellipsis left-[670px] '
                                    >
                                        {stg_english}
                                    </span>
                                    <span
                                        className='w-11  uppercase absolute z-50 bg-transparent top-[860px] font-semibold text-ellipsis left-[670px] '
                                    >
                                        {stg_hindi}
                                    </span>
                                    <span
                                        className='w-11  uppercase absolute z-50 bg-transparent top-[890px] font-semibold text-ellipsis left-[670px] '
                                    >
                                        {stg_urdu}
                                    </span>
                                    <span
                                        className='w-40  absolute z-50  pl-2 top-[735px] font-bold text-base  text-ellipsis right-[40px] '                                    >
                                        {DiplomaSerialId}
                                    </span>
                                </>
                                :
                                <>
                                    <input type='text' value={DiplomaSerialId}
                                        placeholder='Enter Diploma No'
                                        onChange={(e) => setDiplomaSerialId(e.target.value)}
                                        className='w-40 placeholder:text-[#f7811f] placeholder:font-medium absolute z-50 bg-transparent border-[#f7811f] border-4 border-dashed outline-none pl-2 top-[745px] font-bold text-base  text-ellipsis right-[40px] '
                                    />
                                    {/* start date */}
                                    <input type='text' value={startdate !== "" ? startdate : ""}

                                        onChange={(e) => setstartdate(e.target.value)}
                                        className='w-32  absolute z-50 bg-transparent border-[#f7811f] border-4 border-dashed outline-none top-[625px] font-bold  text-ellipsis left-40 '
                                    />
                                    {/* start date */}
                                    <input type='text' value={enddate}
                                        onChange={(e) => setenddate(e.target.value)}
                                        className='w-32  absolute z-50 bg-transparent border-[#f7811f] border-4 border-dashed outline-none pl-2 top-[625px] font-bold  text-ellipsis left-[320px] '
                                    />
                                    {/* issue date */}

                                    <input type='text' value={issuedate}
                                        onChange={(e) => setissuedate(e.target.value)}
                                        className='w-64  absolute z-50 bg-transparent border-[#f7811f] border-4 border-dashed outline-none pl-2 top-[785px] font-bold text-base  text-ellipsis left-[100px] '
                                    />

                                    {/* marksheet data */}

                                    <input type='text' value={typing_english} onChange={(e) => Settyping_english(e.target.value)}
                                        className='w-11  uppercase absolute z-50 border-[#f7811f] border-4 border-dashed outline-none bg-transparent top-[845px] font-semibold text-ellipsis left-[490px] ' />
                                    <input type='text' value={typing_hindi} onChange={(e) => Settyping_hindi(e.target.value)}
                                        className='w-11  uppercase absolute z-50 border-[#f7811f] border-4 border-dashed outline-none bg-transparent top-[870px] font-semibold text-ellipsis left-[490px]' />
                                    <input type='text' value={typing_urdu} onChange={(e) => Settyping_urdu(e.target.value)}
                                        className='w-11  uppercase absolute z-50 border-[#f7811f] border-4 border-dashed outline-none bg-transparent top-[900px] font-semibold text-ellipsis left-[490px] ' />

                                    <input type='text' value={stg_english} onChange={(e) => Setstg_english(e.target.value)}
                                        className='w-11  uppercase absolute z-50 border-[#f7811f] border-4 border-dashed outline-none bg-transparent top-[845px] font-semibold text-ellipsis left-[660px] ' />
                                    <input type='text' value={stg_hindi} onChange={(e) => Setstg_hindi(e.target.value)}
                                        className='w-11  uppercase absolute z-50 border-[#f7811f] border-4 border-dashed outline-none bg-transparent top-[870px] font-semibold text-ellipsis left-[660px] ' />
                                    <input type='text' value={stg_urdu} onChange={(e) => setstg_urdu(e.target.value)}
                                        className='w-11  uppercase absolute z-50 border-[#f7811f] border-4 border-dashed outline-none bg-transparent top-[900px] font-semibold text-ellipsis left-[660px] ' />
                                </>

                        }



                        {/* <h1 type='text' value={''} onChange={(e) => setcourse(e.target.value)} className='w-[480px] absolute z-50 bg-transparent top-[350px] font-bold  text-ellipsis left-[330px] ' /> */}
                        {/* <h1 className='absolute z-50 top-[380px] font-bold  text-ellipsis left-[330px]  '><span className='pr-[62px]'>Course Duration </span>: 12 Months</h1> */}


                    </div>
                </div>
                <div className='absolute top-10 right-24'>
                    {
                        PDFbutton &&
                        <button onClick={handleDownloadPDF} class="relative  inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                            <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                            <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                            <span class="relative" >Download PDF</span>
                        </button>
                    }
                    {
                        SubmitButton === false &&
                        <div className='block mx-auto mt-5'>
                            <button onClick={CertificateHandler} class="relative   inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                                <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                <span class="relative" >Submit</span>
                            </button>
                        </div>
                    }

                    {
                        SubmitButton &&
                        <div className='block mx-auto mt-5'>
                            <button onClick={CertificateUpdateHandler} class="relative   inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                                <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                <span class="relative" >Update</span>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default T_Createcertificate;
