"use client";

import { useNavigate } from "react-router-dom";

// Teacher Dashboard
function TeacherDashboard() {
    const navigate = useNavigate();
    const studentsData = [
        { id: 1, name: 'John Doe', attended: true },
        { id: 2, name: 'Jane Smith', attended: true },
        { id: 3, name: 'Bob Johnson', attended: false },
        { id: 4, name: 'Alice Brown', attended: true },
        { id: 5, name: 'Tom White', attended: false }
    ];
    const totalStudents = studentsData.length;
    const attendedStudents = studentsData.filter(s => s.attended).length;
    const notAttendedStudents = totalStudents - attendedStudents;

    return (
        <div className="p-8">
            {
                // userData?.role === "director" &&
                <div className="">
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-4">
                        <div className="bg-blue-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                            <h2 className="text-xl">Total Students</h2>
                            <p className="text-3xl font-bold">{totalStudents}</p>
                        </div>
                        <div className="bg-green-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                            <h2 className="text-xl">Attended Students</h2>
                            <p className="text-3xl font-bold">{attendedStudents}</p>
                        </div>
                        <div className="bg-purple-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                            <h2 className="text-xl">Not Attended Students</h2>
                            <p className="text-3xl font-bold">{notAttendedStudents}</p>
                        </div>
                        <div className="bg-yellow-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                        <button className="mt-4 bg-[#FF0000] font-medium text-white p-2 rounded" onClick={() => navigate('/home/teacherquestionset')}>Set Questions</button>

                        </div>
                        <div className="bg-yellow-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                        <button className="mt-4 ml-4 bg-green-500 text-white p-2 rounded" onClick={() => navigate('/home/questionlist')}>View Questions</button>

                        </div>
                    </div>
                </div>
            }      
        </div>
    );
}
export default TeacherDashboard;