"use client";
import { useState } from "react";

// Teacher Question Set
function TeacherQuestionSet() {
    const [questions, setQuestions] = useState([]);
    const [course, setCourse] = useState('Math');
    const [newQuestion, setNewQuestion] = useState({ text: '', options: ['', '', '', ''], correctAnswer: '' });

    const addQuestion = () => {
        setQuestions([...questions, { ...newQuestion, course }]);
        setNewQuestion({ text: '', options: ['', '', '', ''], correctAnswer: '' });
    };
    const coursesData = ['DCA', 'ADCA', 'TYPING'];

    const handleCorrectAnswerSelection = (index) => {
        setNewQuestion({ ...newQuestion, correctAnswer: `${index + 1}` });
    };

    return (
        <div className="p-8">
            <h1 className="text-2xl font-bold">Set Questions for {course}</h1>
            <select onChange={(e) => setCourse(e.target.value)} className="border rounded-md p-2 mb-4 mt-2">
                {coursesData.map((c, index) => <option key={index} value={c}>{c}</option>)}
            </select>
            <textarea  placeholder="Question" value={newQuestion.text} onChange={(e) => setNewQuestion({ ...newQuestion, text: e.target.value })} className="block w-full bg-[#f2f2f2] p-2 border rounded-md outline-[#2e3193] mb-2" />
            {newQuestion.options.map((option, index) => (
                <div className="flex items-center" key={index}>
                    <input 
                        type="radio" 
                        name="correctAnswer" 
                        checked={newQuestion.correctAnswer === `${index + 1}`} 
                        onChange={() => handleCorrectAnswerSelection(index)} 
                        className="w-5 h-5 mr-2" 
                    />
                    <input 
                        type="text" 
                        placeholder={`Option ${index + 1}`} 
                        value={option} 
                        onChange={(e) => {
                            const newOptions = [...newQuestion.options];
                            newOptions[index] = e.target.value;
                            setNewQuestion({ ...newQuestion, options: newOptions });
                        }} 
                        className="block bg-[#f2f2f2] w-full p-2 border rounded-md outline-[#f78221] mb-2" 
                    />
                </div>
            ))}
            <button className="bg-green-500 text-white p-2 rounded" onClick={addQuestion}>Add Question</button>
        </div>
    );
}

export default TeacherQuestionSet;
