import React from 'react';
import {  Route, Routes } from 'react-router-dom';
import Home from '../FrontWebsite/Home/Home';
import Home2 from '../Dashboard/Home/home';
import StudentPage from '../Dashboard/Pages/studentRegiser/StudentPage';
import CoursePage from '../Dashboard/Pages/Course/CoursePage';
import LoginForm from '../Auth/login/LoginForm';
import Protected from './Protected';
import Dashboardpage from '../Dashboard/Pages/dashboard/Dashboardpage'
import Franchisepage from '../Dashboard/Pages/Franchise/Franchisepage';
import Certificatepage from '../Dashboard/Pages/certificate/Certificatepage';
import Institution from '../Dashboard/Pages/Institution/Institution';
import RegisterPage from '../Auth/register/RegisterPage';
import Courses from '../FrontWebsite/Pages/Courses/Courses';
import Franchise from '../FrontWebsite/Pages/Franchise/Franchise';
import ThisCourse from '../FrontWebsite/Pages/Courses/ThisCourse';
import StudentQuery from '../Dashboard/Pages/Query/Student_Query';
import FranchiseQuery from '../Dashboard/Pages/Query/Franchise_Query';
import FFranchiseQuery from '../FrontWebsite/Pages/Query/Franchise_query'
import FStudentQuery from '../FrontWebsite/Pages/Query/Student_Query'
import FContact from '../FrontWebsite/Pages/Query/Contact';
import Cverify from '../FrontWebsite/Pages/verify/Cverify';
import ForgetPage from '../Auth/login/ForgetPage';
import ResetPassword from '../Auth/login/ResetPassword';
import TeacherQuestionSet from '../Dashboard/Exam/TeacherQuestionSet';
import TeacherDashboard from '../Dashboard/Exam/TeacherDashboard';
import QuestionList from '../Dashboard/Exam/QuestionList';
import StudentDashboard from '../Dashboard/Exam/StudentDashboard';
import StudentExam from '../Dashboard/Exam/StudentExam';
function AppRouter() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/certificateverify" element={<Cverify />} />
        <Route path="/courses" element={<Courses />} /> 
        <Route path="/studentQuerys" element={<FStudentQuery/>}/>
        <Route path="/contact" element={<FContact/>}/>
        <Route path="/franchisequerys" element={<FFranchiseQuery/>}/>
        <Route path="/courses/:courseid" element={<ThisCourse />} />
        <Route path="/courses/:courseid" element={<ThisCourse />} />
        <Route path="/franchise" element={<Franchise />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/forgetpage" element={<ForgetPage />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/home" element={<Protected Component={Home2}/>} >
            <Route path="dashboardpage" element={<Dashboardpage/>}/>
            <Route path="certificatepage" element={<Certificatepage/>}/>

            <Route path="teacherquestionset" element={<TeacherQuestionSet/>}/>
            <Route path="teacherdashboard" element={<TeacherDashboard/>}/>
            <Route path="questionlist" element={<QuestionList/>}/>
            <Route path="studentdashboard" element={<StudentDashboard/>}/>
            <Route path="studentexam" element={<StudentExam/>}/>

            <Route path="franchisepage" element={<Franchisepage/>}/>
            <Route path="institution" element={<Institution/>}/>
            <Route path="studentpage" element={<StudentPage/>}/>
            <Route path="studentquery" element={<StudentQuery/>}/>
            <Route path="franchisequery" element={<FranchiseQuery/>}/>
            <Route path="coursePage" element={<CoursePage/>}/>
            <Route path="registerpage" element={<RegisterPage/>}/>
        </Route>
        
        
      </Routes>
  );
}

export default AppRouter;
