import React, { useState } from 'react';

function QuestionList() {
  const [selectedCourse, setSelectedCourse] = useState('All');

  const dummyQuestions = [
    { course: 'DCA', text: 'What is 2 + 2?', options: ['3', '4', '5', '6'], correctAnswer: '2' },
    { course: 'ADCA', text: 'What is the boiling point of water?', options: ['90°C', '100°C', '110°C', '120°C'], correctAnswer: '2' }
  ];

  const courses = ['All', ...new Set(dummyQuestions.map(q => q.course))];
  const filteredQuestions = selectedCourse === 'All' ? dummyQuestions : dummyQuestions.filter(q => q.course === selectedCourse);

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Question List</h1>
      <select onChange={(e) => setSelectedCourse(e.target.value)} className="border rounded-md p-2 mb-4">
        {courses.map((course, index) => (
          <option key={index} value={course}>{course}</option>
        ))}
      </select>

      {filteredQuestions.length > 0 ? filteredQuestions.map((q, index) => (
        <div key={index} className="border p-4 mb-4 rounded bg-[#f2f2f2]">
          <h2 className="font-semibold">Course: {q.course}</h2>
          <p>{q.text}</p>
          <ul>
            {q.options.map((option, i) => (
              <li key={i} className={q.correctAnswer === `${i + 1}` ? 'text-green-500' : ''}>{option}</li>
            ))}
          </ul>
        </div>
      )) : <p>No questions available for the selected course.</p>}
    </div>
  );
}

export default QuestionList;
